import React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


function Patient({ setPatientName, patientName, anes }) {


    const namesList = [
        "Dr Chullikal",
        "Dr Coltura",
        "Dr Decleire",
        "Dr De Cooman",
        "Dr Dhoore",
        "Dr Discret",
        "Dr Gille",
        "Dr Houma",
        "Dr Klewais",
        "Dr Roelands",
        "Dr Romont",
        "Dr Schaub",
        "Dr Soudon",
        "Dr van der Vorst",
        "Dr Venmans",
        "Dr Verbrugge",
        "Dr Verheyden",
        "Dr Verhaeghe B",
        "Dr Verhaeghe K",
        "Dr Weren"
    ];

    const [name, setName] = useState('');

    const handleChange = (event) => {
        anes(event.target.value)
        setName(event.target.value);

    };

    const handleNamePatient = (event) => {
        const nomPatient = event.target.value;
        setPatientName(nomPatient);


    }
    return (
        <React.Fragment>
            <div className="patient">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            sx={{ minWidth: "100%" }}
                            margin="dense"
                            variant="outlined"
                            size="small"
                            placeholder="Copy/Paste from Cliniweb"
                            label={"Last Name\xa0-\xa0First Name\xa0-\xa0Birthday"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={patientName}
                            onChange={handleNamePatient}
                        />

                    </Grid>

                    <Grid item xs={2}>
                        <TextField

                            multiline
                            margin="dense"
                            variant="outlined"
                            size="small"
                            label="Operation"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl
                            margin="dense"
                            size='small'
                            variant="outlined"
                            sx={{ minWidth: "100%" }}
                        >
                            <InputLabel >Anesthesist</InputLabel>
                            <Select

                                value={name}
                                onChange={handleChange}
                                label="Anesthesist"

                            >
                                {namesList.map((nameList, index) => {
                                    return (
                                        <MenuItem value={nameList} key={index}>{nameList}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>

                        <TextField
                            margin="dense"
                            variant="outlined"
                            size="small"
                            label="Assistant"

                        />

                    </Grid>
                    <Grid item xs={2}>
                        <TextField margin="dense" variant="outlined" size="small" label="Surgeon" />
                    </Grid>
                </Grid>
            </div>

        </React.Fragment>
    )
}

export default Patient;